import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-155aba5b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page_header" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "formContainer"
}
const _hoisted_4 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_5 = { class: "d-flex align-items-center justify-content-between mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!
  const _component_provider_garment_productions_table = _resolveComponent("provider-garment-productions-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        type: 'h1',
        localizedKey: "Edit provider",
        text: "Edit provider",
        class: "mb-0"
      }),
      _createElementVNode("button", {
        class: "btn btn-tertiary",
        disabled: _ctx.saveDisabled,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save()))
      }, [
        (_ctx.providerIdentifier)
          ? (_openBlock(), _createBlock(_component_localized_text, {
              key: 0,
              localizedKey: "Update",
              text: "Update"
            }))
          : (_openBlock(), _createBlock(_component_localized_text, {
              key: 1,
              localizedKey: "Create",
              text: "Create"
            }))
      ], 8, _hoisted_2)
    ]),
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_form_field, {
            type: "text",
            labelKey: "Provider name",
            labelValue: "Provider name",
            placeholderKey: "Provider name",
            placeholderValue: "Provider name",
            modelValue: _ctx.model.providerName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.providerName) = $event))
          }, null, 8, ["modelValue"]),
          (_ctx.providerIdentifier)
            ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Managed garments",
                    text: "Managed garments",
                    class: "fw_700 m-0"
                  }),
                  _createElementVNode("button", {
                    class: "btn btn-tertiary",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editGarmentManagement(-1)))
                  }, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Add garment management",
                      text: "Add garment management"
                    })
                  ])
                ]),
                _createVNode(_component_provider_garment_productions_table, {
                  garmentProductions: _ctx.info.garmentProductions,
                  showCTAs: true,
                  onEditGarmentManagement: _ctx.editGarmentManagement,
                  onDeleteGarmentManagement: _ctx.deleteGarmentManagement
                }, null, 8, ["garmentProductions", "onEditGarmentManagement", "onDeleteGarmentManagement"])
              ]))
            : (_openBlock(), _createBlock(_component_localized_text, {
                key: 1,
                localizedKey: "In order to enter the rest of the information, it is necessary to create the provider name.",
                text: "In order to enter the rest of the information, it is necessary to create the provider name.",
                class: "fs_12",
                type: "p"
              }))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}