
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, GarmentClient, ProviderClient } from '@/services/Services';
import store from '@/store';
import * as OM from '@/Model';

@Options({})
export default class EditGarmentManagementModal extends Vue {

    @Prop() isNew: boolean;
    @Prop() callback: any;

    @Prop({
        default: () => new OM.EditGarmentProductionsVM()
    }) editGarmentProductions: OM.EditGarmentProductionsVM;
    localModel: OM.EditGarmentProductionsVM = new OM.EditGarmentProductionsVM();

    garmentsAvailable: OM.TextIdentifier[] = [];
    countriesTexts: string[] = [];

    yesOrNoSelect: OM.TextIdentifier[] = [];
    userKnowDetails = false.toString();
    providerManageEntireProduct = true.toString();
    singleCountryForAllProductionPhases: string = "";

    loaded: boolean = false;

    created() {
        this.yesOrNoSelect = [
            {
                identifier: true.toString(),
                text: this.$localizationService.getLocalizedValue("Yes", "Yes")
            },
            {
                identifier: false.toString(),
                text: this.$localizationService.getLocalizedValue("No", "No")
            }
        ]

        Promise.all([
            store.state.baseEmployeeClient.getAvailableLicenses(),
            ElectricityEmissionClient.getCountries(true)
        ])
        .then(xs => {
            xs[0].forEach(element => {
                if(!this.garmentsAvailable.find(x => x.identifier == element.garment.identifier))
                    this.garmentsAvailable.push(element.garment);
            });

            xs[1].forEach(element => {
                this.countriesTexts.push(element.text);
            });
        })
        .finally(() => {
            this.localModel.provider.identifier = this.editGarmentProductions.provider.identifier;
    
            if(!this.isNew) {
                this.localModel.garment = this.editGarmentProductions.garment;
                this.localModel.finalPrice = this.editGarmentProductions.finalPrice;
                this.localModel.batchCode = this.editGarmentProductions.batchCode;
                this.localModel.manageEntireProduct = this.editGarmentProductions.manageEntireProduct;
                this.providerManageEntireProduct = this.localModel.manageEntireProduct.toString();

                var differentCountriesCount: number = 0;
                for(var i = 0; i < this.editGarmentProductions.phaseCountryList.length; i++) {
                    if(i > 0) {
                        if(this.editGarmentProductions.phaseCountryList[i].country != this.editGarmentProductions.phaseCountryList[i - 1].country)
                            differentCountriesCount = differentCountriesCount + 1;
                    }
                    this.localModel.phaseCountryList.push({...this.editGarmentProductions.phaseCountryList[i]});
                }
                
                if(differentCountriesCount == 0) {
                    this.userKnowDetails = false.toString();
                    this.singleCountryForAllProductionPhases = this.localModel.phaseCountryList[0].country;
                }
                else
                    this.userKnowDetails = true.toString();
            }

            setTimeout(() => {
                this.loaded = true;
            }, 100);
        })


    }

    useKnowDetailsUpdated(value: string){
        this.userKnowDetails = value;

        if(value == true.toString())
            this.singleCountryForAllProductionPhases = "";
    }

    updateProductionPhases() {
        if(this.editGarmentProductions.garment.identifier != this.localModel.garment.identifier) {
            var el = this.garmentsAvailable.find(x => x.identifier == this.localModel.garment.identifier);
            if(el)
                this.localModel.garment.text = el.text;

            GarmentClient.checkIsFootwear(this.localModel.garment.identifier)
            .then(x => {
                if(x) {
                    this.localModel.phaseCountryList = [];

                    var rawMaterialNewPhaseCountry = new OM.PhaseCountry();
                    rawMaterialNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Raw Material", "Raw Material");
                    this.localModel.phaseCountryList.push(rawMaterialNewPhaseCountry);

                    var cuttingNewPhaseCountry = new OM.PhaseCountry();
                    cuttingNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Cutting", "Cutting");
                    this.localModel.phaseCountryList.push(cuttingNewPhaseCountry);
    
                    var stitchingNewPhaseCountry = new OM.PhaseCountry();
                    stitchingNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Stitching", "Stitching");
                    this.localModel.phaseCountryList.push(stitchingNewPhaseCountry);

                    var outsoleNewPhaseCountry = new OM.PhaseCountry();
                    outsoleNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Outsole", "Outsole");
                    this.localModel.phaseCountryList.push(outsoleNewPhaseCountry);

                    var lastingNewPhaseCountry = new OM.PhaseCountry();
                    lastingNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Lasting (gluing, finishing, packaging)", "Lasting (gluing, finishing, packaging)");
                    this.localModel.phaseCountryList.push(lastingNewPhaseCountry);

                    var distributionNewPhaseCountry = new OM.PhaseCountry();
                    distributionNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Distribution", "Distribution");
                    this.localModel.phaseCountryList.push(distributionNewPhaseCountry);
                }
                else {
                    GarmentClient.checkIsBag(this.localModel.garment.identifier)
                    .then(x => {
                        if(x) {
                            this.localModel.phaseCountryList = [];

                            var rawMaterialNewPhaseCountry = new OM.PhaseCountry();
                            rawMaterialNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Raw Material", "Raw Material");
                            this.localModel.phaseCountryList.push(rawMaterialNewPhaseCountry);

                            var cuttingNewPhaseCountry = new OM.PhaseCountry();
                            cuttingNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Cutting", "Cutting");
                            this.localModel.phaseCountryList.push(cuttingNewPhaseCountry);
            
                            var makingNewPhaseCountry = new OM.PhaseCountry();
                            makingNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Making", "Making");
                            this.localModel.phaseCountryList.push(makingNewPhaseCountry);

                            var assemblingNewPhaseCountry = new OM.PhaseCountry();
                            assemblingNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Assembling (finishing, packaging)", "Assembling (finishing, packaging)");
                            this.localModel.phaseCountryList.push(assemblingNewPhaseCountry);

                            var distributionNewPhaseCountry = new OM.PhaseCountry();
                            distributionNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Distribution", "Distribution");
                            this.localModel.phaseCountryList.push(distributionNewPhaseCountry);
                        }
                        else {
                            GarmentClient.getGarmentProductionPhases(this.localModel.garment.identifier)
                            .then(x => {
                                this.localModel.phaseCountryList = [];
                                var rawMaterialNewPhaseCountry = new OM.PhaseCountry();
                                rawMaterialNewPhaseCountry.phase = this.$localizationService.getLocalizedValue("Raw Material", "Raw Material");
                                this.localModel.phaseCountryList.push(rawMaterialNewPhaseCountry);
                
                                x.forEach(element => {
                                    var newPhaseCountry = new OM.PhaseCountry();
                                    newPhaseCountry.phase = element.text;
                                    this.localModel.phaseCountryList.push(newPhaseCountry);
                                });
                            })
                        }
                    })
                }
            })
        }
    }

    updateCountryForEachPhase() {
        if(this.singleCountryForAllProductionPhases) {
            this.localModel.phaseCountryList.forEach(element => {
                element.country = this.singleCountryForAllProductionPhases;
            });
        }
    }

    updateManagingProperty(value: string) {
        this.providerManageEntireProduct = value;
        this.localModel.manageEntireProduct = this.providerManageEntireProduct == true.toString();
    }

    clear(index: number) {
        this.localModel.phaseCountryList[index].country = "";
        this.localModel.phaseCountryList[index].price = 0;
    }

    get isSaveDisabled() {
        var checkBasicInfo = !this.localModel.garment.identifier || !this.localModel.batchCode;
        var totalPriceNotOk = true;
        var countryNotOk = true;
        
        if(this.providerManageEntireProduct == true.toString()) {
            totalPriceNotOk = this.localModel.finalPrice == null || this.localModel.finalPrice == 0;
            if(this.userKnowDetails == false.toString())
                countryNotOk = this.singleCountryForAllProductionPhases == "";
            else {
                var countCountriesInserted = 0;
                this.localModel.phaseCountryList.forEach(element => {
                    if(element.country)
                        countCountriesInserted++;
                });

                countryNotOk = countCountriesInserted < this.localModel.phaseCountryList.length;
            }
        }
        else {
            totalPriceNotOk = false;
            countryNotOk = false;
        }

        return checkBasicInfo || totalPriceNotOk || countryNotOk;
    }

    save() {
        if(!this.localModel.manageEntireProduct)
            this.localModel.finalPrice = 0;
            
        ProviderClient.editGarmentProductions(this.localModel)
        .then(x => {
            this.callback();
        })
    }

}
